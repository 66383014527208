import React, { useState, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useToast } from '@sdir/utilities/lib/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { ProjectActivityV3 } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { getStatusList } from '@sdir/blueprint.et/lib/helpers';
import {
  FocusAreaFilterOptions,
  ScopeFilterOptions,
  TaskStatusFilterOptions,
  TaskTypeFilterOptions
} from '@sdir/blueprint.et/lib/types/enums';
import {
  indexedFilterDropdownTypes,
  filterDropdownTypes,
  ActiveFilters,
  defaultActiveFilters
} from '@sdir/blueprint.et/lib/components/Organisms/TaskFilters/TaskFiltersV1';
import { surveyOptionsApi } from '../../httpclient';
import {
  UPDATE_ACTIVITY_STATUS_COMPLETED,
  SET_SURVEY_OPTIONS
} from '../../store/constants/action-types';

const useTaskFilter = (
  activities: ProjectActivityV3[] | null | undefined,
  includeNotRelevantActivities: boolean,
  setIncludeNotRelevantActivities: React.Dispatch<React.SetStateAction<boolean>>,
  getActivityList?: () => void,
  resetSelectedActivities?: () => void
) => {
  const dispatch = useDispatch();
  const { setError } = useToast();
  const intl = useIntl();
  const surveyOptions = useSelector((state: any) => state.surveyState.surveyOptions);
  const activityStatusChanged = useSelector((state: any) => state.appState.activityStatusChanged);
  const [activeFilters, setActiveFilters] = useState<ActiveFilters>({
    ...defaultActiveFilters,
    status: [
      TaskStatusFilterOptions.Accepted,
      TaskStatusFilterOptions.AwaitingSdir,
      TaskStatusFilterOptions.AwaitingShippingCompany,
      TaskStatusFilterOptions.Reaction
    ]
  });
  const [
    dropdownOptionsForMapping,
    setDropdownOptionsForMapping
  ] = useState<indexedFilterDropdownTypes | null>(null);
  const [filteredActivities, setFilteredActivities] = useState<ProjectActivityV3[]>();

  const { callApi: getSurveyOptions, errorObject: surveyOptionsError } = useApi({
    apiFunction: () => surveyOptionsApi.v1SurveyoptionsGetsurveyoptionsGet(),
    callApiOnInit: false,

    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'survey.intervals.toast.error' })}: ${err}`);
    },
    successCallback: data => {
      dispatch({ type: SET_SURVEY_OPTIONS, payload: data });
    }
  });

  useEffect(() => {
    // survey options are required for mapping of dropdown values
    if (!surveyOptions) {
      getSurveyOptions();
    } else {
      updateFilteredActivities();
    }
  }, [activities, activeFilters, surveyOptions]);

  const updateFilteredActivities = () => {
    // To refresh activity list if activity status was changed by Bekreft Oppgave/Klar for inspection
    if (activityStatusChanged?.subjectAreaUri && getActivityList) {
      getActivityList();
      dispatch({ type: UPDATE_ACTIVITY_STATUS_COMPLETED });
    }
    let newFilteredActivities: ProjectActivityV3[] = filterTasksByStatus();
    newFilteredActivities = filterByType(newFilteredActivities);
    newFilteredActivities = filterTasksByCertificate(newFilteredActivities);
    newFilteredActivities = filterTasksByScope(newFilteredActivities);
    newFilteredActivities = filterTasksByFocusArea(newFilteredActivities);
    newFilteredActivities = filterTaskByIntervals(newFilteredActivities);
    newFilteredActivities = filterTaskByInspectionTypes(newFilteredActivities);
    newFilteredActivities = filterTaskByUnscheduledSurveyTypes(newFilteredActivities);
    setFilteredActivities(newFilteredActivities);
  };

  const filterByType = (activitiesByStatus: ProjectActivityV3[]) => {
    if (!activitiesByStatus) return [];
    switch (activeFilters.taskType) {
      case TaskTypeFilterOptions.ShowAll:
        return activitiesByStatus;
      case TaskTypeFilterOptions.DocumentControl:
        return activitiesByStatus.filter(activity =>
          activity.checkpointType?.includes('DocumentControl')
        );
      case TaskTypeFilterOptions.Inspection:
        return activitiesByStatus.filter(activity =>
          activity.checkpointType?.includes('Inspection')
        );
      default:
        return activitiesByStatus;
    }
  };

  const filterTasksByStatus = () => {
    if (!activities) return [];
    if (
      activeFilters.status.length === 0 ||
      activeFilters.status.includes(TaskStatusFilterOptions.ShowAll)
    ) {
      return [...activities];
    }
    const filteredTasks: any[] = [];
    const selectedStatusList = getStatusList(activeFilters.status);
    activities?.forEach(activity => {
      if (
        (activity?.lastEvent?.status && selectedStatusList.includes(activity.lastEvent.status)) ||
        (!activity?.lastEvent &&
          activeFilters.status.includes(TaskStatusFilterOptions.AwaitingShippingCompany))
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTasksByCertificate = (projectActivities: ProjectActivityV3[]) => {
    if (!projectActivities) return [];
    if (
      activeFilters?.certificates?.length === 0 ||
      activeFilters.certificates.includes(TaskStatusFilterOptions.ShowAll)
    ) {
      return [...projectActivities];
    }
    const filteredTasks: ProjectActivityV3[] = [];

    projectActivities?.forEach(activity => {
      if (
        activeFilters.certificates.some(ai =>
          activity?.certificates?.some(
            certificate => ai === dropdownOptionsForMapping?.certificates[certificate]
          )
        )
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTasksByScope = (projectActivities: ProjectActivityV3[]) => {
    if (!projectActivities) return [];
    if (!activeFilters?.scope || activeFilters.scope === ScopeFilterOptions.ShowAll) {
      return [...projectActivities];
    }
    const filteredTasks: ProjectActivityV3[] = [];
    projectActivities?.forEach(activity => {
      if (
        (activeFilters.scope === ScopeFilterOptions.Mandatory && activity?.scope === true) ||
        (activeFilters.scope === ScopeFilterOptions.Expanded && activity?.scope === false)
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTasksByFocusArea = (projectActivities: ProjectActivityV3[]) => {
    if (!projectActivities || activeFilters.focusArea === FocusAreaFilterOptions.No) return [];
    return [...projectActivities];
  };

  const filterTaskByIntervals = filteredActivitiesList => {
    if (!filteredActivitiesList) return [];
    if (activeFilters?.intervals?.length === 0) {
      return [...filteredActivitiesList];
    }
    const filteredTasks: ProjectActivityV3[] = [];
    filteredActivitiesList?.forEach(activity => {
      if (
        activeFilters.intervals.some(ai =>
          activity?.intervals.some(
            interval => ai === dropdownOptionsForMapping?.intervals[interval.value]
          )
        )
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTaskByInspectionTypes = (projectActivities: ProjectActivityV3[]) => {
    if (!projectActivities) return [];
    if (activeFilters?.inspectionTypes?.length === 0) {
      return [...projectActivities];
    }

    const filteredTasks: ProjectActivityV3[] = [];
    projectActivities?.forEach(activity => {
      if (
        activeFilters.inspectionTypes.some(ai =>
          activity.surveyTypes?.some(
            sType => sType.value && ai === dropdownOptionsForMapping?.inspectionTypes[sType.value]
          )
        )
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTaskByUnscheduledSurveyTypes = filteredActivitiesList => {
    if (!filteredActivitiesList) return [];
    if (
      !activeFilters.unscheduledSurveyTypes ||
      activeFilters.unscheduledSurveyTypes.length === 0
    ) {
      return [...filteredActivitiesList];
    }
    const filteredTasks: ProjectActivityV3[] = [];
    filteredActivitiesList?.forEach(activity => {
      if (
        activeFilters?.unscheduledSurveyTypes?.some(ai =>
          activity?.unscheduledSurveyTypes.some(
            unscheduledSurveyType =>
              ai === dropdownOptionsForMapping?.unscheduledSurveyTypes[unscheduledSurveyType.value]
          )
        )
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const uniqueDropdownValues = useMemo(() => {
    if (!activities || activities?.length === 0) return null;
    const intervals: string[] = [];
    const inspectionTypes: string[] = [];
    const certificates: string[] = [];
    const unscheduledSurveyTypes: string[] = [];
    activities?.forEach(activity => {
      activity.intervals?.forEach(i => {
        if (i.value && !intervals.find(x => x === i.value)) intervals.push(i.value);
      });
      activity.surveyTypes?.forEach(i => {
        if (i.value && !inspectionTypes.find(x => x === i.value)) inspectionTypes.push(i.value);
      });
      activity.certificates?.forEach(i => {
        if (!certificates.find(x => x === i)) certificates.push(i);
      });
      activity.unscheduledSurveyTypes?.forEach(i => {
        if (i.value && !unscheduledSurveyTypes.find(x => x === i.value))
          unscheduledSurveyTypes.push(i.value);
      });
    });
    return { intervals, inspectionTypes, certificates, unscheduledSurveyTypes };
  }, [activities]);

  const availableDropdownValues: filterDropdownTypes = useMemo(() => {
    const mappedDropdownOptions = {
      intervals: {},
      inspectionTypes: {},
      certificates: {},
      buildingMaterials: {},
      unscheduledSurveyTypes: {}
    };
    if (uniqueDropdownValues && surveyOptions) {
      // get latest values for survey options
      uniqueDropdownValues.intervals.forEach(interval => {
        const intervalMapped = surveyOptions?.intervals?.find(x => x.value === interval);
        if (intervalMapped) {
          mappedDropdownOptions.intervals[interval] = intervalMapped.label;
        }
      });
      uniqueDropdownValues.inspectionTypes.forEach(inspectionType => {
        const inspectionTypeMapped = surveyOptions?.surveyTypes?.find(
          x => x.value === inspectionType
        );
        if (inspectionTypeMapped) {
          mappedDropdownOptions.inspectionTypes[inspectionType] = inspectionTypeMapped.label;
        }
      });
      uniqueDropdownValues.certificates.forEach(certificate => {
        const certificateMapped = surveyOptions?.certificateTypes?.find(
          x => x.value === certificate
        );
        if (certificateMapped) {
          mappedDropdownOptions.certificates[certificate] = certificateMapped.label;
        }
      });
      uniqueDropdownValues.unscheduledSurveyTypes.forEach(unscheduledSurveyType => {
        const unscheduledSurveyTypeMapped = surveyOptions?.surveyTypes?.find(
          x => x.value === unscheduledSurveyType
        );
        if (unscheduledSurveyTypeMapped) {
          mappedDropdownOptions.unscheduledSurveyTypes[unscheduledSurveyType] =
            unscheduledSurveyTypeMapped.label;
        }
      });
      setDropdownOptionsForMapping(mappedDropdownOptions);
    }
    return {
      intervals: Object.values(mappedDropdownOptions.intervals),
      inspectionTypes: Object.values(mappedDropdownOptions.inspectionTypes),
      certificates: Object.values(mappedDropdownOptions.certificates),
      buildingMaterial: Object.values(mappedDropdownOptions.buildingMaterials),
      unscheduledSurveyTypes: Object.values(mappedDropdownOptions.unscheduledSurveyTypes)
    };
  }, [uniqueDropdownValues, surveyOptions]);

  const onFiltersChanged = (newActiveFilters: ActiveFilters) => {
    if (
      !includeNotRelevantActivities &&
      newActiveFilters.status.includes(TaskStatusFilterOptions.NotRelevant)
    ) {
      setIncludeNotRelevantActivities(true);
    }
    setActiveFilters(newActiveFilters);
    updateFilteredActivities();
  };

  const refreshFiltersAndSelectedActivities = (selectedFilters: ActiveFilters) => {
    resetSelectedActivities && resetSelectedActivities();
    setActiveFilters(selectedFilters);
  };

  const resetStatusAndCertificateFilters = () => {
    setActiveFilters({
      ...activeFilters,
      taskType: defaultActiveFilters.taskType,
      status: defaultActiveFilters.status
    });
  };

  const taskFilterData = {
    filteredActivities,
    activeFilters,
    onFiltersChanged,
    resetStatusAndCertificateFilters,
    refreshFiltersAndSelectedActivities,
    availableDropdownValues
  };

  return taskFilterData;
};

export default useTaskFilter;
