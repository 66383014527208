import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { DataTable, SdiTrash, SdirButton, TextLink, styles } from '@sdir/sds';
import { SimpleStatusIndicator, VesselGridVesselCard } from '@sdir/blueprint.et/lib/components';
import { useDataSorting, useToast } from '@sdir/utilities/lib/hooks';
import { generatePath, useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  ConstructionNoticeSubmittedForReviewResult,
  GetMyUnsubmittedConstructionNoticeResult
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { VesselState } from '@sdir/blueprint.et/lib/types/enums';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { SortableProperty } from '@sdir/utilities/lib/types/sorting';
import ToggleListViewButtons, { ViewState } from '../../Atoms/ToggleListViewButtons';
import { Routes } from '../../Templates/Routes';
import { constructionNoticeApi } from '../../../httpclient';

interface ConstructionNoticeProps {
  submittedConstructionNotices: ConstructionNoticeSubmittedForReviewResult[] | undefined;
  unsubmittedConstructionNotices: GetMyUnsubmittedConstructionNoticeResult[] | undefined;
  getUnsubmittedConstructionNotices: () => void;
}

interface TableRow<T> extends SortableProperty<T> {
  label: string;
}

interface DraftConstructionNoticeColumns {
  constructionNumber: string;
  constructionShipyard: string;
  vesselType: string;
  processing: string;
  deleteDraft?: string;
}
interface ReviewConstructionNoticeColumns extends DraftConstructionNoticeColumns {
  submittedDate: string;
}

const getViewStateFromLocalStorage = (): ViewState => {
  const localState = localStorage.getItem('fres:constructonnotice:viewstate');
  if (localState === 'grid' || localState === 'list') return localState;
  return 'grid';
};

const ConstructionNotice: React.FC<ConstructionNoticeProps> = ({
  submittedConstructionNotices = [],
  unsubmittedConstructionNotices = [],
  getUnsubmittedConstructionNotices
}) => {
  const [viewState, setViewState] = useState<ViewState>(() => getViewStateFromLocalStorage());
  const intl = useIntl();
  const navigate = useNavigate();
  const { setError, setSuccess } = useToast();

  const draftColumns: TableRow<DraftConstructionNoticeColumns>[] = [
    {
      label: intl.formatMessage({ id: 'constructionnotice.table.column.constructionyard' }),
      key: 'constructionShipyard'
    },
    {
      label: intl.formatMessage({ id: 'constructionnotice.table.column.constructionnumber' }),
      key: 'constructionNumber'
    },
    {
      label: intl.formatMessage({ id: 'constructionnotice.table.column.vesseltype' }),
      key: 'vesselType'
    },
    {
      label: '',
      key: 'deleteDraft'
    }
  ];

  const reviewColumns: TableRow<ReviewConstructionNoticeColumns>[] = useMemo(() => {
    const columns: TableRow<ReviewConstructionNoticeColumns>[] = [
      {
        label: intl.formatMessage({ id: 'constructionnotice.table.column.submittedDate' }),
        key: 'submittedDate'
      },
      {
        label: intl.formatMessage({ id: 'constructionnotice.table.column.constructionyard' }),
        key: 'constructionShipyard'
      },
      {
        label: intl.formatMessage({ id: 'constructionnotice.table.column.constructionnumber' }),
        key: 'constructionNumber'
      }
    ];

    const processingSomeConstructionNotices = submittedConstructionNotices.some(
      cn => cn.isConfirmed && !cn.projectUid
    );

    if (processingSomeConstructionNotices) {
      columns.push({
        label: '',
        key: 'processing'
      });
    }
    return columns;
  }, [submittedConstructionNotices]);

  const {
    sortedData: unsubmittedTableSortedData,
    setSortedBy: setUnsubmittedTableSortedBy
  } = useDataSorting<any>(unsubmittedConstructionNotices, draftColumns);

  const {
    sortedData: submittedTableSortedData,
    setSortedBy: setSubmittedTableSortedBy
  } = useDataSorting<any>(submittedConstructionNotices, reviewColumns);

  const handleViewState = (newState: ViewState) => {
    localStorage.setItem('fres:constructonnotice:viewstate', newState);
    setViewState(newState);
  };

  const { callApi: deleteConstructionNoticeDraft } = useApi({
    apiFunction: constructionnoticeid => {
      return constructionNoticeApi.v1ConstructionnoticeDeleteconstructionnoticedraftConstructionNoticeUidDelete(
        constructionnoticeid
      );
    },
    successCallback: () => {
      setSuccess(intl.formatMessage({ id: 'constructionnotice.vesselcard.delete.success' }));
      getUnsubmittedConstructionNotices();
    },
    errorCallback: err => {
      if (err.length > 0) {
        setError(intl.formatMessage({ id: 'constructionnotice.vesselcard.delete.error' }));
      }
    }
  });

  const onCellClicked = (row, key) => {
    if (key !== 'deleteDraft') {
      navigate(
        generatePath(Routes.constructionUid, {
          currentStep: 1,
          constructionnoticeid: row.constructionNoticeId
        })
      );
    }
  };

  return (
    <Container>
      <Header>
        <ToggleWrapper>
          <ToggleListViewButtons state={viewState} onChange={state => handleViewState(state)} />
          <SdirButton
            text={intl.formatMessage({ id: 'constructionnotice.button.createconstructonnotice' })}
            onClick={() => navigate(generatePath(Routes.construction, { currentStep: 1 }))}
          />
        </ToggleWrapper>
      </Header>
      <ContentCard>
        <ContentCardHeader>
          {intl.formatMessage({ id: 'constructionnotice.card.header.draft' })}
        </ContentCardHeader>
        <ContentCardContent>
          {!unsubmittedConstructionNotices.length ? (
            <NoDataLabel>
              {intl.formatMessage({ id: 'constructionnotice.card.nodata.draft' })}
            </NoDataLabel>
          ) : (
            <>
              {viewState === 'grid' ? (
                <VesselCardWrapper>
                  {unsubmittedConstructionNotices?.map(
                    (unsubmitted: GetMyUnsubmittedConstructionNoticeResult) => (
                      <VesselGridVesselCard
                        key={unsubmitted.constructionNoticeId}
                        toProject={() =>
                          navigate(
                            generatePath(Routes.constructionUid, {
                              currentStep: 1,
                              constructionnoticeid: unsubmitted.constructionNoticeId
                            })
                          )
                        }
                        onDelete={() =>
                          deleteConstructionNoticeDraft(unsubmitted.constructionNoticeId)
                        }
                        vesselType={unsubmitted.vesselType}
                        constructionNumber={unsubmitted.constructionNumber}
                        constructionShipyard={unsubmitted.constructionShipyard}
                        constructionNoticeVesselState={VesselState.DRAFT}
                      />
                    )
                  )}
                </VesselCardWrapper>
              ) : (
                <DataTableWrapper>
                  <DataTable
                    colWidth={['100rem', '100rem', '100rem']}
                    columns={draftColumns}
                    data={unsubmittedTableSortedData.map(row => ({
                      ...row,
                      deleteDraft: (
                        <StyledTextLink
                          icon={<SdiTrash size="s" />}
                          text=""
                          click={() => deleteConstructionNoticeDraft(row.constructionNoticeId)}
                        />
                      )
                    }))}
                    onHeaderClick={setUnsubmittedTableSortedBy}
                    onCellClick={onCellClicked}
                    sortableKeys={['constructionShipyard', 'constructionNumber', 'vesselType']}
                  />
                </DataTableWrapper>
              )}
            </>
          )}
        </ContentCardContent>
      </ContentCard>
      <ContentCard>
        <ContentCardHeader>
          {intl.formatMessage({ id: 'constructionnotice.card.header.submitted' })}
        </ContentCardHeader>
        <ContentCardContent>
          {!submittedConstructionNotices.length ? (
            <NoDataLabel>
              {intl.formatMessage({ id: 'constructionnotice.card.nodata.constructionnotice' })}
            </NoDataLabel>
          ) : (
            <>
              {viewState === 'grid' ? (
                <ConstructionNoticeContainer>
                  {submittedConstructionNotices?.map(
                    (submitted: ConstructionNoticeSubmittedForReviewResult) => (
                      <VesselGridVesselCard
                        key={submitted.id}
                        constructionNoticeVesselState={VesselState.SUBMITTED}
                        vesselType={submitted.vesselType}
                        constructionShipyard={submitted.constructionShipyard}
                        constructionNumber={submitted.constructionNumber}
                        statusComponent={
                          submitted.isConfirmed && !submitted.projectUid ? (
                            <SimpleStatusIndicator
                              text={intl.formatMessage({ id: 'constructionnotice.processing' })}
                              color={styles.colors.primaryLightBlue1}
                            />
                          ) : (
                            undefined
                          )
                        }
                        toProject={() =>
                          navigate(
                            generatePath(Routes.constructionNoticeReview, {
                              constructionnoticeid: submitted.id
                            })
                          )
                        }
                        submittedAt={submitted.submittedDate}
                      />
                    )
                  )}
                </ConstructionNoticeContainer>
              ) : (
                <DataTableWrapper>
                  <DataTable
                    columns={reviewColumns}
                    data={submittedTableSortedData.map(row => ({
                      ...row,
                      submittedDate: row.submittedDate
                        ? moment(row.submittedDate).format('DD.MM.YYYY')
                        : '-',
                      processing:
                        row.isConfirmed && !row.projectUid ? (
                          <SimpleStatusIndicator
                            text={intl.formatMessage({ id: 'constructionnotice.processing' })}
                            color={styles.colors.primaryLightBlue1}
                          />
                        ) : (
                          ''
                        )
                    }))}
                    onHeaderClick={setSubmittedTableSortedBy}
                    onRowClick={row =>
                      navigate(
                        generatePath(Routes.constructionNoticeReview, {
                          constructionnoticeid: row.id
                        })
                      )
                    }
                    sortableKeys={[
                      'submittedDate',
                      'constructionShipyard',
                      'constructionNumber',
                      'vesselType'
                    ]}
                  />
                </DataTableWrapper>
              )}
            </>
          )}
        </ContentCardContent>
      </ContentCard>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-bottom: 5rem;
`;

const ContentCard = styled.div`
  box-shadow: ${({ theme }) => theme.card.boxShadow};
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.font.white};
`;

const ContentCardContent = styled.div`
  padding: 4rem;
`;

const ContentCardHeader = styled.div`
  padding: 3rem;
  background-color: ${({ theme }) => theme.colors.primary10};
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  font-size: 3rem;
`;

const ConstructionNoticeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4rem;
  margin-bottom: 4rem;
`;

const NoDataLabel = styled.label`
  font-size: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const VesselCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
`;

const DataTableWrapper = styled.div`
  width: 75rem;

  div {
    padding: 0;
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  align-items: flex-end;
`;

const StyledTextLink = styled(TextLink)`
  &:focus,
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.alert.errorDark};
    }
  }
`;

export default ConstructionNotice;
