import { SET_SELECTED_CERTIFICATE_DATA, SET_SURVEY_OPTIONS } from '../constants/action-types';
import { surveyState } from '../constants/types';

const initialSurveyState: surveyState = {
  selectedSurveyCertificateData: {
    $type: '',
    surveyType: {},
    checkpointType: undefined,
    certificateType: undefined,
    inspectionTypes: undefined,
    surveyId: undefined,
    interval: undefined
  },
  surveyOptions: null
};

export default function surveyReducer(state = initialSurveyState, action) {
  switch (action.type) {
    case SET_SELECTED_CERTIFICATE_DATA:
      return {
        ...state,
        selectedSurveyCertificateData: action.payload
      };
    case SET_SURVEY_OPTIONS:
      return {
        ...state,
        surveyOptions: action.payload
      };
    default:
      return state;
  }
}
