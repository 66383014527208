/* eslint-disable react/jsx-wrap-multilines */
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { SdirTabs, Loader } from '@sdir/sds';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { VesselCardSection, VesselHeaderCard } from '@sdir/blueprint.et/lib/components';
import { useToast } from '@sdir/utilities/lib/hooks';
import { VesselDataAreaV2 } from '@sdir/httpclient/lib/clients/et/construction';
import useCertificate from '../../../services/hooks/useCertificate';
// eslint-disable-next-line max-len
import CertificateSection from '../../Organisms/CertificateSection';
// import VesselCardSectionContainer from '../../Organisms/VesselCardSectionContainer';
import { vesselDataControllerV3Api } from '../../../httpclient';
import { Routes } from '../../Templates/Routes';

const VesselCardPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { setError } = useToast();
  const { vesselUid, tab } = useParams() as { vesselUid: string; tab: string };

  const vesselTabOptions = {
    vesselcard: 'vesselcard',
    certificate: 'certificate'
  };

  const {
    callApi: getVesselCardData,
    loading: getVesselCardDataLoading,
    result: vesselCardData
  } = useApi({
    apiFunction: uid =>
      vesselDataControllerV3Api.v3Vesseldatacontrollerv3GetvesseldataAreaGet(
        uid,
        VesselDataAreaV2.All
      ),
    errorCallback: err => {
      if (err.length > 0) {
        setError(
          `${intl.formatMessage(
            { id: 'error.load' },
            { error: intl.formatMessage({ id: 'vesselcard.tab.title' }).toLowerCase() }
          )}`
        );
      }
    }
  });

  const getTabIndex = () => tabOptions.findIndex(el => el === tab);

  const filterTabOptions = tabs => {
    const tabTitles = [
      intl.formatMessage({ id: 'vesselcard.tab.title' }),
      intl.formatMessage({ id: 'certificates.tab.title' })
    ];
    return {
      tabs,
      tabTitles
    };
  };

  const tabOptions = filterTabOptions(Object.keys(vesselTabOptions))?.tabs;
  const tabTitles = filterTabOptions(Object.keys(vesselTabOptions))?.tabTitles;

  const onTabClick = (key: number) => {
    navigate(generatePath(Routes.vesselcardpage, { tab: tabOptions[key], vesselUid }));
  };

  const { getVesselCertificates, loading } = useCertificate();
  const vesselCertificates = useSelector((state: any) => state.appState.vesselCertificates);

  useEffect(() => {
    if (['certificate'].includes(tab) && vesselUid && !vesselCertificates) {
      (async () => {
        await getVesselCertificates(vesselUid);
      })();
    }
  }, [tab, vesselUid]);

  useEffect(() => {
    if (vesselUid) {
      getVesselCardData(vesselUid);
    }
  }, [vesselUid]);

  const renderChildren = () => {
    switch (tabOptions[getTabIndex()]) {
      case 'certificate':
        return <CertificateSection loading={loading} />;
      case 'vesselcard':
        return (
          <VesselCardSection
            vesselCardData={vesselCardData}
            vesselCardDataLoading={getVesselCardDataLoading}
            vesselRiskAndReactionData={undefined}
            vesselRiskAndReactionDataLoading={false}
          />
        );
      default:
        return null;
    }
  };

  return vesselUid ? (
    <>
      <Container>
        {getVesselCardDataLoading && <Loader />}
        {vesselCardData && <VesselHeaderCard vessel={vesselCardData} />}
        <>
          <TabContainer>
            <SdirTabs
              activeKey={getTabIndex()}
              titles={tabTitles}
              eachTabClick={k => onTabClick(k)}
            />
          </TabContainer>
          {renderChildren()}
        </>
      </Container>
    </>
  ) : null;
};

const TabContainer = styled.div`
  height: 6rem;
  margin-top: 5rem;
`;

const Container = styled.section`
  width: 100%;
  max-width: 188rem;
  margin: 2rem auto 0;
`;

export default VesselCardPage;
