import React, { useEffect } from 'react';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useToast } from '@sdir/utilities/lib/hooks';
import { useSelector, useDispatch } from 'react-redux';
import {
  GetVesselDataAreaV2Result,
  VesselDataAreaV2
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { GetVesselDataResultV3 } from '@sdir/httpclient/lib/clients/core/commonvessel';
import { VesselCardSection } from '@sdir/blueprint.et/lib/components';
import { useIntl } from 'react-intl';
import { vesselDataControllerV3Api, getVesselDataAreaV2Api } from '../../httpclient';
import { setVesselCardData, setVesselCardDataLoading } from '../../store/actions/action';

interface Props {
  vesselUid: string;
  projectUid: string;
}

const VesselCardSectionContainer: React.FC<Props> = ({ vesselUid, projectUid }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { setError } = useToast();
  const {
    vesselCardData,
    vesselCardDataLoading
  }: { vesselCardData: GetVesselDataResultV3; vesselCardDataLoading: boolean } = useSelector(
    (state: any) => state.appState
  );

  const {
    vesselRiskAndReactionData,
    vesselRiskAndReactionDataLoading
  }: {
    vesselRiskAndReactionData: GetVesselDataAreaV2Result;
    vesselRiskAndReactionDataLoading: boolean;
  } = useSelector((state: any) => state.appState);

  const { callApi: getRiskAndReactionData } = useApi({
    apiFunction: uid =>
      getVesselDataAreaV2Api.v2Getvesseldataareav2GetvesselareaProjectIdGet(
        uid,
        VesselDataAreaV2.All,
        projectUid
      ),
    successCallback: res => {
      dispatch(setVesselCardData(res));
      dispatch(setVesselCardDataLoading(false));
    },
    errorCallback: err => {
      if (err.length > 0) {
        dispatch(setVesselCardDataLoading(false));
        setError(
          `${intl.formatMessage(
            { id: 'error.load' },
            { error: intl.formatMessage({ id: 'vesselcard.tab.title' }).toLowerCase() }
          )}`
        );
      }
    }
  });

  const { callApi: getVesselCardData } = useApi({
    apiFunction: uid =>
      vesselDataControllerV3Api.v3Vesseldatacontrollerv3GetvesseldataAreaGet(
        uid,
        VesselDataAreaV2.All
      ),
    successCallback: res => {
      dispatch(setVesselCardData(res));
      dispatch(setVesselCardDataLoading(false));
    },
    errorCallback: err => {
      if (err.length > 0) {
        dispatch(setVesselCardDataLoading(false));
        setError(
          `${intl.formatMessage(
            { id: 'error.load' },
            { error: intl.formatMessage({ id: 'vesselcard.tab.title' }).toLowerCase() }
          )}`
        );
      }
    }
  });

  useEffect(() => {
    if (
      vesselUid &&
      !vesselCardDataLoading &&
      (!vesselCardData || vesselCardData.uid !== vesselUid)
    ) {
      dispatch(setVesselCardDataLoading(true));
      getVesselCardData(vesselUid);
      getRiskAndReactionData(vesselUid);
    }
  }, [vesselUid]);

  return (
    <VesselCardSection
      vesselRiskAndReactionData={vesselRiskAndReactionData}
      vesselRiskAndReactionDataLoading={vesselRiskAndReactionDataLoading}
      vesselCardData={vesselCardData}
      vesselCardDataLoading={vesselCardDataLoading}
      projectUid={projectUid}
    />
  );
};

export default VesselCardSectionContainer;
