import { HttpClient } from '@sdir/httpclient/lib/HttpClient';
import {
  ActivityApi,
  ExemptionApi,
  OnboardCaseWorkerApi,
  ProjectApi,
  ReactionApi,
  ReplyApi,
  RequirementApi,
  AddTeamMemberToProjectApi,
  GetProjectTeamMembersApi,
  GetMyProjectsTasksApi,
  GetProjectCaseWorkersApi,
  AssignTaskToCaseWorkerApi,
  SigningReactionApi,
  DownloadAttachmentApi,
  NotRelevantApi,
  PlannedCertificatesApi,
  GetDocumentApi,
  GetVesselCertificatesApi,
  ConstructionNoticeApi,
  GetVesselDataAreaV2Api
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { ActivityApi as ActivityMetadataApi } from '@sdir/httpclient/lib/clients/et/activity';
import {
  SurveyOptionsApi,
  SpecificationsApi,
  VesselRequirementsApi,
  CheckpointsApi
} from '@sdir/httpclient/lib/clients/core/requirement';
import {
  VesselsApi,
  VesselDataControllerV3Api
} from '@sdir/httpclient/lib/clients/core/commonvessel';
import { IncidentsApi } from '@sdir/httpclient/lib/clients/et/incident';
import { SearchApiApi, PersonApi, VesselApi } from '@sdir/httpclient/lib/clients/core/search';
import {
  AttachmentApi as AddresseeAttachmentApi,
  AttachmentV2Api as AddresseeAttachmentV2Api,
  PersonApi as CommonPersonApi,
  PersonReplacementApi
} from '@sdir/httpclient/lib/clients/core/commonaddressee';
import { AdditionalCompetenceApiApi } from '@sdir/httpclient/lib/clients/aps/additionalcompetence';
import { AssessmentApi } from '@sdir/httpclient/lib/clients/aps/assessor';
import { HealthCheckApi } from '@sdir/httpclient/lib/clients/aps/health';
import {
  PersonalCertificateApi,
  CertificateApi
} from '@sdir/httpclient/lib/clients/aps/personalcertificate';
import {
  CourseEducationApi,
  CourseApi,
  InstitutionCourseApi
} from '@sdir/httpclient/lib/clients/aps/course';
import {
  OccupationRegisterApi,
  SeagoingTimeCalculatorApi,
  SeagoingServicesApi,
  SeagoingRegistrationApi,
  ApplicationSeagoingApi
} from '@sdir/httpclient/lib/clients/aps/seagoing';
import { GetRegulationLawTextApi } from '@sdir/httpclient/lib/clients/et/regulationtext';
import { ADGroupApi, UserPermissionApi } from '@sdir/httpclient/lib/clients/core/authorization';

import { GetApi, SeagoingApi } from '@sdir/httpclient/lib/clients/aps/application';

import { ScopeEndpointsApi } from '@sdir/httpclient/lib/clients/et/survey';

const clientId = process.env.REACT_APP_CLIENT_ID;
const authority = process.env.REACT_APP_EXTERNALAUTHURL;
export const baseUrl = process.env.REACT_APP_API_URL;
const httpClient = new HttpClient(baseUrl);
export const BaseApi = httpClient.client;
httpClient.setDefaultHeaders();
httpClient.addAPIMSubscriptionKeyHeader(process.env.REACT_APP_SUBSCRIPTIONKEY);
httpClient.addOICDAuthorizationHeader(authority, clientId);

const httpClientLawText = new HttpClient(baseUrl);
httpClientLawText.addRequestMiddleware(req => {
  req.headers = {
    ...req.headers,
    'Accept': 'text/html',
    'Accept-Language': 'nb-NO'
  };
  return req;
});

const getAccessToken = () => {
  const userString = sessionStorage.getItem(`oidc.user:${authority}:${clientId}`);
  if (!userString) return '';
  const user = JSON.parse(userString);
  return user?.access_token ?? '';
};

export const getConfigHeaders = () => {
  const accessToken = getAccessToken();
  return {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Accept-Language': 'nb-NO',
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTIONKEY,
    'Authorization': accessToken ? `Bearer ${accessToken}` : ''
  };
};

export const activityMetadataApi = new ActivityMetadataApi(
  undefined,
  `${baseUrl}/et-activity-internal`,
  httpClient.client
);

export const activityApi = new ActivityApi(
  undefined,
  `${baseUrl}/et-vesselcaseworker-internal`,
  httpClient.client
);

export const exemptionApi = new ExemptionApi(
  undefined,
  `${baseUrl}/et-vesselcaseworker-internal`,
  httpClient.client
);

export const projectApi = new ProjectApi(
  undefined,
  `${baseUrl}/et-vesselcaseworker-internal`,
  httpClient.client
);

export const constructionNoticeApi = new ConstructionNoticeApi(
  undefined,
  `${baseUrl}/et-vesselcaseworker-internal`,
  httpClient.client
);

export const reactionApi = new ReactionApi(
  undefined,
  `${baseUrl}/et-vesselcaseworker-internal`,
  httpClient.client
);

export const replyToCustomerApi = new ReplyApi(
  undefined,
  `${baseUrl}/et-vesselcaseworker-internal`,
  httpClient.client
);

export const notRelevantApi = new NotRelevantApi(
  undefined,
  `${baseUrl}/et-vesselcaseworker-internal`,
  httpClient.client
);

export const downloadAttachmentApi = new DownloadAttachmentApi(
  undefined,
  `${baseUrl}/et-vesselcaseworker-internal`,
  httpClient.client
);

export const signingReactionApi = new SigningReactionApi(
  undefined,
  `${baseUrl}/et-vesselcaseworker-internal`,
  httpClient.client
);

export const requirementApi = new RequirementApi(
  undefined,
  `${baseUrl}/et-vesselcaseworker-internal`,
  httpClient.client
);

export const plannedCertificatesApi = new PlannedCertificatesApi(
  undefined,
  `${baseUrl}/et-vesselcaseworker-internal`,
  httpClient.client
);

export const getVesselCertificatesApi = new GetVesselCertificatesApi(
  undefined,
  `${baseUrl}/et-vesselcaseworker-internal`,
  httpClient.client
);

export const getProjectTeamMembersApi = new GetProjectTeamMembersApi(
  undefined,
  `${baseUrl}/et-vesselcaseworker-internal`,
  httpClient.client
);

export const getDocumentApi = new GetDocumentApi(
  undefined,
  `${baseUrl}/et-vesselcaseworker-internal`,
  httpClient.client
);

export const addTeamMemberToProjectApi = new AddTeamMemberToProjectApi(
  undefined,
  `${baseUrl}/et-vesselcaseworker-internal`,
  httpClient.client
);

export const getMyProjectsTasksApi = new GetMyProjectsTasksApi(
  undefined,
  `${baseUrl}/et-vesselcaseworker-internal`,
  httpClient.client
);

export const onboardCaseWorkerApi = new OnboardCaseWorkerApi(
  undefined,
  `${baseUrl}/et-vesselcaseworker-internal`,
  httpClient.client
);

export const getProjectCaseWorkersApi = new GetProjectCaseWorkersApi(
  undefined,
  `${baseUrl}/et-vesselcaseworker-internal`,
  httpClient.client
);

export const assignTaskToCaseWorkerApi = new AssignTaskToCaseWorkerApi(
  undefined,
  `${baseUrl}/et-vesselcaseworker-internal`,
  httpClient.client
);

export const getVesselDataAreaV2Api = new GetVesselDataAreaV2Api(
  undefined,
  `${baseUrl}/et-vesselcaseworker-internal`,
  httpClient.client
);

export const specificationsApi = new SpecificationsApi(
  undefined,
  `${baseUrl}/core-requirement-internal`,
  httpClient.client
);

export const surveyOptionsApi = new SurveyOptionsApi(
  undefined,
  `${baseUrl}/core-requirement-internal`,
  httpClient.client
);

export const vesselRequirementsApi = new VesselRequirementsApi(
  undefined,
  `${baseUrl}/core-requirement-internal`,
  httpClient.client
);

export const checkpointsApi = new CheckpointsApi(
  undefined,
  `${baseUrl}/core-requirement-internal`,
  httpClient.client
);

export const searchApiApi = new SearchApiApi(
  undefined,
  `${baseUrl}/core-search-internal`,
  httpClient.client
);

export const personApi = new PersonApi(
  undefined,
  `${baseUrl}/core-search-internal`,
  httpClient.client
);

export const commonPersonApi = new CommonPersonApi(
  undefined,
  `${baseUrl}/core-commonaddressee-internal`,
  httpClient.client
);

export const personReplacementApi = new PersonReplacementApi(
  undefined,
  `${baseUrl}/core-commonaddressee-internal`,
  httpClient.client
);

export const additionalCompetenceApiApi = new AdditionalCompetenceApiApi(
  undefined,
  `${baseUrl}/aps-additionalcompetence-internal`,
  httpClient.client
);

export const assessmentApi = new AssessmentApi(
  undefined,
  `${baseUrl}/aps-assessor-internal`,
  httpClient.client
);

export const courseEducationApi = new CourseEducationApi(
  undefined,
  `${baseUrl}/aps-course-internal`,
  httpClient.client
);

export const institutionCourseApi = new InstitutionCourseApi(
  undefined,
  `${baseUrl}/aps-course-internal`,
  httpClient.client
);

export const courceApi = new CourseApi(
  undefined,
  `${baseUrl}/aps-course-internal`,
  httpClient.client
);

export const seagoingTimeCalculatorApi = new SeagoingTimeCalculatorApi(
  undefined,
  `${baseUrl}/aps-seagoing-internal`,
  httpClient.client
);

export const seagoingServicesApi = new SeagoingServicesApi(
  undefined,
  `${baseUrl}/aps-seagoing-internal`,
  httpClient.client
);

export const seagoingApi = new SeagoingApi(
  undefined,
  `${baseUrl}/aps-application-internal`,
  httpClient.client
);

export const applicationSeagoingApi = new ApplicationSeagoingApi(
  undefined,
  `${baseUrl}/aps-seagoing-internal`,
  httpClient.client
);

export const seagoingRegistrationApi = new SeagoingRegistrationApi(
  undefined,
  `${baseUrl}/aps-seagoing-internal`,
  httpClient.client
);

export const healthCheckApi = new HealthCheckApi(
  undefined,
  `${baseUrl}/aps-health-internal`,
  httpClient.client
);

export const personalCertificateApi = new PersonalCertificateApi(
  undefined,
  `${baseUrl}/aps-personalCertificate-internal`,
  httpClient.client
);

export const certificateApi = new CertificateApi(
  undefined,
  `${baseUrl}/aps-personalCertificate-internal`,
  httpClient.client
);

export const vesselApi = new VesselApi(
  undefined,
  `${baseUrl}/core-search-internal`,
  httpClient.client
);

export const getRegulationLawTextApi = new GetRegulationLawTextApi(
  undefined,
  `${baseUrl}/et-regulationtext-internal`,
  httpClient.client
);

export const scopeEndpointsApi = new ScopeEndpointsApi(
  undefined,
  `${baseUrl}/et-survey-internal`,
  httpClient.client
);

export const getSimulatorRegulationLawTextApi = new GetRegulationLawTextApi(
  undefined,
  `${baseUrl}/et-regulationtext-internal`,
  httpClientLawText.client
);

export const incidentsApi = new IncidentsApi(
  undefined,
  `${baseUrl}/et-incident-internal`,
  httpClient.client
);

export const aDGroupApi = new ADGroupApi(
  undefined,
  `${baseUrl}/core-authorization-internal`,
  httpClient.client
);

export const addresseeAttachmentApi = new AddresseeAttachmentApi(
  undefined,
  `${baseUrl}/core-commonaddressee-internal`,
  httpClient.client
);

export const addresseeAttachmentV2Api = new AddresseeAttachmentV2Api(
  undefined,
  `${baseUrl}/core-commonaddressee-internal`,
  httpClient.client
);

export const getApplicationApi = new GetApi(
  undefined,
  `${baseUrl}/aps-application-internal`,
  httpClient.client
);

export const occupationRegisterApi = new OccupationRegisterApi(
  undefined,
  `${baseUrl}/aps-seagoing-internal`,
  httpClient.client
);

export const vesselsApi = new VesselsApi(
  undefined,
  `${baseUrl}/core-commonvessel-internal`,
  httpClient.client
);

export const vesselDataControllerV3Api = new VesselDataControllerV3Api(
  undefined,
  `${baseUrl}/core-commonvessel-internal`,
  httpClient.client
);

export const userPermissionApi = new UserPermissionApi(
  undefined,
  `${baseUrl}/core-authorization-internal`,
  httpClient.client
);
