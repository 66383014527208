import { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SuggestedScopeActivity } from '@sdir/httpclient/lib/clients/et/survey';
import { getStatusList } from '@sdir/blueprint.et/lib/helpers';
import {
  FocusAreaFilterOptions,
  ScopeFilterOptions,
  TaskStatusFilterOptions,
  TaskTypeFilterOptions
} from '@sdir/blueprint.et/lib/types/enums';
import { SurveyCertificateData } from '@sdir/blueprint.et/lib/components';
import {
  indexedFilterDropdownTypes,
  filterDropdownTypes,
  ActiveFilters,
  defaultActiveFilters
} from '@sdir/blueprint.et/lib/components/Organisms/TaskFilters/TaskFiltersV1';

const useScopeFilter = (resetSelectedActivities?: () => void) => {
  const surveyOptions = useSelector((state: any) => state.surveyState.surveyOptions);
  const selectedSurvey: SurveyCertificateData = useSelector(
    (state: any) => state.surveyState.selectedSurveyCertificateData
  );
  const activities: SuggestedScopeActivity[] = useSelector(
    (state: any) => state.appState.scopeActivitiesList
  );
  const [activeFilters, setActiveFilters] = useState<ActiveFilters>({
    ...defaultActiveFilters
  });
  const [
    dropdownOptionsForMapping,
    setDropdownOptionsForMapping
  ] = useState<indexedFilterDropdownTypes | null>(null);

  useEffect(() => {
    if (dropdownOptionsForMapping) {
      const selectedSurveyFilters = { ...defaultActiveFilters };
      // set default filters from selected survey
      if (
        selectedSurvey.certificateType?.value &&
        dropdownOptionsForMapping.certificates?.[selectedSurvey.certificateType.value]
      ) {
        selectedSurveyFilters.certificates = [
          dropdownOptionsForMapping.certificates[selectedSurvey.certificateType.value]
        ];
      }
      if (selectedSurvey?.checkpointType?.label && selectedSurvey?.checkpointType.label !== '') {
        selectedSurveyFilters.taskType =
          selectedSurvey.checkpointType.value?.split('#')[1]?.toLowerCase() ===
          TaskTypeFilterOptions.Inspection
            ? TaskTypeFilterOptions.Inspection
            : TaskTypeFilterOptions.DocumentControl;
      }
      if (
        selectedSurvey.interval?.value &&
        dropdownOptionsForMapping.intervals?.[selectedSurvey.interval.value]
      ) {
        selectedSurveyFilters.intervals = [
          dropdownOptionsForMapping.intervals[selectedSurvey.interval.value]
        ];
      }
      if (
        selectedSurvey.surveyType?.value &&
        dropdownOptionsForMapping.inspectionTypes?.[selectedSurvey.surveyType.value]
      ) {
        selectedSurveyFilters.inspectionTypes = [
          dropdownOptionsForMapping.inspectionTypes[selectedSurvey.surveyType.value]
        ];
      }
      // Show included and excluded activities by default
      selectedSurveyFilters.status = [
        TaskStatusFilterOptions.Excluded,
        TaskStatusFilterOptions.Included
      ];
      setActiveFilters(selectedSurveyFilters);
    }
  }, [selectedSurvey, dropdownOptionsForMapping]);

  const filterByType = activitiesByStatus => {
    if (!activitiesByStatus) return [];
    switch (activeFilters.taskType) {
      case TaskTypeFilterOptions.ShowAll:
        return activitiesByStatus;
      case TaskTypeFilterOptions.DocumentControl:
        return activitiesByStatus.filter(activity =>
          activity.checkpointType.value.includes('DocumentControl')
        );
      case TaskTypeFilterOptions.Inspection:
        return activitiesByStatus.filter(activity =>
          activity.checkpointType.value.includes('Inspection')
        );
      default:
        return activitiesByStatus;
    }
  };

  const filterTasksByStatus = () => {
    if (!activities || activities.length === 0) return [];
    if (activeFilters.status.includes(TaskStatusFilterOptions.ShowAll)) {
      return [...activities];
    }
    const filteredTasks: any[] = [];
    const selectedStatusList = getStatusList(activeFilters.status);
    activities?.forEach(activity => {
      if (
        // by default, not relevant should be filtered out
        (activity.isNotRelevant === false && activeFilters.status.length === 0) ||
        (activity.isNotRelevant === true &&
          selectedStatusList.includes(TaskStatusFilterOptions.NotRelevant)) ||
        (activity.isIncludedInCurrentSurvey === false &&
          selectedStatusList.includes(TaskStatusFilterOptions.Excluded)) ||
        (activity.isIncludedInCurrentSurvey === true &&
          selectedStatusList.includes(TaskStatusFilterOptions.Included))
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTasksByCertificate = filteredActivitiesList => {
    if (!filteredActivitiesList) return [];
    if (activeFilters?.certificates?.length === 0) {
      return [...filteredActivitiesList];
    }
    const filteredTasks: SuggestedScopeActivity[] = [];
    filteredActivitiesList?.forEach(activity => {
      if (
        activeFilters.certificates.some(ai =>
          activity?.certificates.some(
            certificate => ai === dropdownOptionsForMapping?.certificates[certificate.value]
          )
        )
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTasksByScope = filteredActivitiesList => {
    if (!filteredActivitiesList) return [];
    if (!activeFilters?.scope || activeFilters.scope === ScopeFilterOptions.ShowAll) {
      return [...filteredActivitiesList];
    }
    const filteredTasks: SuggestedScopeActivity[] = [];
    filteredActivitiesList?.forEach(activity => {
      if (
        (activeFilters.scope === ScopeFilterOptions.Mandatory && activity?.isMandatory === true) ||
        (activeFilters.scope === ScopeFilterOptions.Expanded && activity?.isMandatory !== true)
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTasksByFocusArea = filteredActivitiesList => {
    if (!filteredActivitiesList) return [];
    if (!activeFilters?.focusArea || activeFilters.focusArea === FocusAreaFilterOptions.Both) {
      return [...filteredActivitiesList];
    }
    const filteredTasks: SuggestedScopeActivity[] = [];
    filteredActivitiesList?.forEach(activity => {
      if (
        (activeFilters.focusArea === FocusAreaFilterOptions.Yes && activity.focusArea) ||
        (activeFilters.focusArea === FocusAreaFilterOptions.No && !activity.focusArea)
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTaskByIntervals = filteredActivitiesList => {
    if (!filteredActivitiesList) return [];
    if (activeFilters?.intervals?.length === 0) {
      return [...filteredActivitiesList];
    }
    const filteredTasks: SuggestedScopeActivity[] = [];
    filteredActivitiesList?.forEach(activity => {
      if (
        activeFilters.intervals.some(ai =>
          activity?.intervals.some(
            interval => ai === dropdownOptionsForMapping?.intervals[interval.value]
          )
        )
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTaskByInspectionTypes = filteredActivitiesList => {
    if (!filteredActivitiesList) return [];
    if (activeFilters?.inspectionTypes?.length === 0) {
      return [...filteredActivitiesList];
    }
    const filteredTasks: SuggestedScopeActivity[] = [];
    filteredActivitiesList?.forEach(activity => {
      if (
        activeFilters.inspectionTypes.some(ai =>
          activity?.inspectionTypes.some(
            inspectionType =>
              ai === dropdownOptionsForMapping?.inspectionTypes[inspectionType.value]
          )
        )
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTaskByUnscheduledSurveyTypes = filteredActivitiesList => {
    if (!filteredActivitiesList) return [];
    if (
      !activeFilters.unscheduledSurveyTypes ||
      activeFilters.unscheduledSurveyTypes.length === 0
    ) {
      return [...filteredActivitiesList];
    }
    const filteredTasks: SuggestedScopeActivity[] = [];
    filteredActivitiesList?.forEach(activity => {
      if (
        activeFilters?.unscheduledSurveyTypes?.some(ai =>
          activity?.unscheduledSurveyTypes.some(
            unscheduledSurveyType =>
              ai === dropdownOptionsForMapping?.unscheduledSurveyTypes[unscheduledSurveyType.value]
          )
        )
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const uniqueDropdownValues = useMemo(() => {
    if (activities.length === 0) return null;
    const intervals: string[] = [];
    const inspectionTypes: string[] = [];
    const certificates: string[] = [];
    const buildingMaterials: string[] = [];
    const unscheduledSurveyTypes: string[] = [];
    activities?.forEach(activity => {
      activity.intervals?.forEach(i => {
        if (i.value && !intervals.find(x => x === i.value)) intervals.push(i.value);
      });
      activity.inspectionTypes?.forEach(i => {
        if (i.value && !inspectionTypes.find(x => x === i.value)) inspectionTypes.push(i.value);
      });
      activity.certificates?.forEach(i => {
        if (i.value && !certificates.find(x => x === i.value)) certificates.push(i.value);
      });
      activity.buildingMaterials?.forEach(i => {
        if (i.value && !buildingMaterials.find(x => x === i.value)) buildingMaterials.push(i.value);
      });
      activity.unscheduledSurveyTypes?.forEach(i => {
        if (i.value && !unscheduledSurveyTypes.find(x => x === i.value))
          unscheduledSurveyTypes.push(i.value);
      });
    });
    return { intervals, inspectionTypes, buildingMaterials, certificates, unscheduledSurveyTypes };
  }, [activities]);

  const availableDropdownValues: filterDropdownTypes = useMemo(() => {
    const mappedDropdownOptions = {
      intervals: {},
      inspectionTypes: {},
      certificates: {},
      buildingMaterials: {},
      unscheduledSurveyTypes: {}
    };
    if (uniqueDropdownValues) {
      // get latest values for survey options
      uniqueDropdownValues.intervals.forEach(interval => {
        const intervalMapped = surveyOptions.intervals.find(x => x.value === interval);
        if (intervalMapped) {
          mappedDropdownOptions.intervals[interval] = intervalMapped.label;
        }
      });
      uniqueDropdownValues.inspectionTypes.forEach(inspectionType => {
        const inspectionTypeMapped = surveyOptions?.surveyTypes?.find(
          x => x.value === inspectionType
        );
        if (inspectionTypeMapped) {
          mappedDropdownOptions.inspectionTypes[inspectionType] = inspectionTypeMapped.label;
        }
      });
      uniqueDropdownValues.certificates.forEach(certificate => {
        const certificateMapped = surveyOptions?.certificateTypes?.find(
          x => x.value === certificate
        );
        if (certificateMapped) {
          mappedDropdownOptions.certificates[certificate] = certificateMapped.label;
        }
      });
      uniqueDropdownValues.buildingMaterials.forEach(buildingMaterial => {
        const buildingMaterialMapped = surveyOptions.buildingMaterials.find(
          x => x.value === buildingMaterial
        );
        if (buildingMaterialMapped) {
          mappedDropdownOptions.buildingMaterials[buildingMaterial] = buildingMaterialMapped.label;
        }
      });
      uniqueDropdownValues.unscheduledSurveyTypes.forEach(unscheduledSurveyType => {
        const unscheduledSurveyTypeMapped = surveyOptions.surveyTypes.find(
          x => x.value === unscheduledSurveyType
        );
        if (unscheduledSurveyTypeMapped) {
          mappedDropdownOptions.unscheduledSurveyTypes[unscheduledSurveyType] =
            unscheduledSurveyTypeMapped.label;
        }
      });
      setDropdownOptionsForMapping(mappedDropdownOptions);
    }
    return {
      intervals: Object.values(mappedDropdownOptions.intervals),
      inspectionTypes: Object.values(mappedDropdownOptions.inspectionTypes),
      certificates: Object.values(mappedDropdownOptions.certificates),
      buildingMaterial: Object.values(mappedDropdownOptions.buildingMaterials),
      unscheduledSurveyTypes: Object.values(mappedDropdownOptions.unscheduledSurveyTypes)
    };
  }, [uniqueDropdownValues, surveyOptions]);

  const filteredActivities = useMemo(() => {
    let newFilteredActivities: Array<SuggestedScopeActivity> = [];
    if (activities.length > 0 && dropdownOptionsForMapping) {
      newFilteredActivities = filterTasksByStatus();
      newFilteredActivities = filterByType(newFilteredActivities);
      newFilteredActivities = filterTasksByCertificate(newFilteredActivities);
      newFilteredActivities = filterTasksByScope(newFilteredActivities);
      newFilteredActivities = filterTasksByFocusArea(newFilteredActivities);
      newFilteredActivities = filterTaskByIntervals(newFilteredActivities);
      newFilteredActivities = filterTaskByInspectionTypes(newFilteredActivities);
      newFilteredActivities = filterTaskByUnscheduledSurveyTypes(newFilteredActivities);
    }
    return newFilteredActivities;
  }, [activities, activeFilters]);

  const onFiltersChanged = (newActiveFilters: ActiveFilters) => {
    setActiveFilters(newActiveFilters);
  };

  const refreshFiltersAndSelectedActivities = (selectedFilters: ActiveFilters) => {
    resetSelectedActivities && resetSelectedActivities();
    setActiveFilters(selectedFilters);
  };

  const resetStatusAndCertificateFilters = () => {
    setActiveFilters({
      ...activeFilters,
      taskType: defaultActiveFilters.taskType,
      status: defaultActiveFilters.status
    });
  };

  const scopeFilterData = {
    filteredActivities,
    activeFilters,
    onFiltersChanged,
    resetStatusAndCertificateFilters,
    refreshFiltersAndSelectedActivities,
    availableDropdownValues
  };

  return scopeFilterData;
};

export default useScopeFilter;
